import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import TemplatesSection from "../../../components/pages/templates/TemplatesSection";
import Content from "../../../components/layout/Content/Content";
import Shoutout from "../../../components/pages/signup/Shoutout";


import mobileWireframeImage from "../../../components/pages/home/HomeExamples/mobile_wireframe_cover.png";
import productivityAppImage from "../../../components/pages/home/HomeExamples/productivity_app_cover.png";
import travelAppImage from "../../../components/pages/home/HomeExamples/travel_app_cover.png";
import basketImage from "../../../components/pages/home/HomeExamples/basket_cover.png";
import bikeImage from "../../../components/pages/home/HomeExamples/bike_rental_cover.png";
import bookImage from "../../../components/pages/home/HomeExamples/book-app-cover.png";
import bankingmobilewireframeImage from "../../../components/pages/home/HomeExamples/wireframe-online-banking-app-mobile-plp.png";
import budgetImage from "../../../components/pages/home/HomeExamples/finance-app-cover.png";
import carsharingImage from "../../../components/pages/home/HomeExamples/car-sharing-app-cover.png";
import cookingImage from "../../../components/pages/home/HomeExamples/cooking-app-cover.png";
import crowdfundingmobileImage from "../../../components/pages/home/HomeExamples/crowdfunding-mobile-plp.png";
import caloriemobileImage from "../../../components/pages/home/HomeExamples/calorie-counter-mobile-plp.png";
import datingImage from "../../../components/pages/home/HomeExamples/dating-app-cover.png";
import eventbookingImage from "../../../components/pages/home/HomeExamples/ticket-booking-app-cover.png";
import fitnessImage from "../../../components/pages/home/HomeExamples/workout-app-cover.png";
import flightImage from "../../../components/pages/home/HomeExamples/flight-booking-app-cover.png";
import groceryImage from "../../../components/pages/home/HomeExamples/grocery-app-cover.png";
import habitImage from "../../../components/pages/home/HomeExamples/habit-app-cover.png";
import healthtrackerImage from "../../../components/pages/home/HomeExamples/health-tracker-app-cover.png";
import learningImage from "../../../components/pages/home/HomeExamples/learning-app-cover.png";
import notesImage from "../../../components/pages/home/HomeExamples/notes-app-cover.png";
import nftmarketImage from "../../../components/pages/home/HomeExamples/nft-market-mobile-plp.png";
import podcastImage from "../../../components/pages/home/HomeExamples/podcast-app-cover.png";
import medicalImage from "../../../components/pages/home/HomeExamples/medical-app-cover.png";
import meditationImage from "../../../components/pages/home/HomeExamples/meditation-app-cover.png";
import mobileuiImage from "../../../components/pages/home/HomeExamples/mobile-ui-kit-cover.png";
import moneytransferImage from "../../../components/pages/home/HomeExamples/money-transfer-app-cover.png";
import materialYouImage from "../../../components/pages/home/HomeExamples/Google_Material_You_cover.png";
import mobileweatherdarkImage from "../../../components/pages/home/HomeExamples/weather-mobile-dark-plp.png";
import mobileweatherlightImage from "../../../components/pages/home/HomeExamples/weather-mobile-light-plp.png";
import smarthomeImage from "../../../components/pages/home/HomeExamples/smart-home-mobile-plp.png";
import jobappImage from "../../../components/pages/home/HomeExamples/job-finding-app-plp.png";
import furniturestoreImage from "../../../components/pages/home/HomeExamples/furniture-store-mobile-plp.png";
import insuranceAppImage from "../../../components/pages/home/HomeExamples/insurance-app-mobile-plp.png";
import audiobookImage from "../../../components/pages/home/HomeExamples/audiobook-mobile-plp.png";
import taximobileImage from "../../../components/pages/home/HomeExamples/tax-mobile-plp.png";
import quizmobileImage from "../../../components/pages/home/HomeExamples/quiz-app-mobile-plp.png";
import moodboardmobileImage from "../../../components/pages/home/HomeExamples/moodboard-app-mobile-plp.png";
import gmdeaImage from "../../../components/pages/home/HomeExamples/GMDEA_cover.png";
import carpoolmobileImage from "../../../components/pages/home/HomeExamples/carpooling-app-mobile-plp.png";
import newsfeedImage from "../../../components/pages/home/HomeExamples/news-app-cover.png";
import todolistImage from "../../../components/pages/home/HomeExamples/to-do-app-cover.png";
import iOSImage from "../../../components/pages/home/HomeExamples/ios15_cover.png";
import messagingAppImage from "../../../components/pages/home/HomeExamples/messaging_app_cover.png";
import sportAppImage from "../../../components/pages/home/HomeExamples/sports_mobile_app_cover.png";
import socialmediaImage from "../../../components/pages/home/HomeExamples/social-media-app-cover.png";
import tablebookingImage from "../../../components/pages/home/HomeExamples/table-booking-app-cover.png";
import transportImage from "../../../components/pages/home/HomeExamples/transport-app-cover.png";
import quickBiteMobileImage from "../../../components/pages/home/HomeExamples/food_delivery_mobile_app_cover.png";
import musicMobileAppImage from "../../../components/pages/home/HomeExamples/music_mobile_app_cover.png";
import cryptoMobileAppImage from "../../../components/pages/home/HomeExamples/crypto_nft_mobile_app_cover.png";
import billTrackerMobileAppImage from "../../../components/pages/home/HomeExamples/utility-tracker-mobile-plp.png";
import foodblogMobileAppImage from "../../../components/pages/home/HomeExamples/food-blog-mobile-plp.jpeg";
import photoSharingMobileAppImage from "../../../components/pages/home/HomeExamples/photo-sharing-social-media-app-plp.png";
import restaurantReviewMobileImage from "../../../components/pages/home/HomeExamples/restaurant-review-mobile-app-plp.png";
import studyMobileAppImage from "../../../components/pages/home/HomeExamples/study-mobile-app-plp.png";
import translationMobileAppImage from "../../../components/pages/home/HomeExamples/translation-mobile-app-plp.png";

const ContentExtended = Styled(Content)`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbContainer = Styled.div`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
  
    margin-bottom: 1rem;
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const Templates = ({ data }) => (
  <Layout>
    <Meta
      title="Mobile App Design Templates | UI Mobile App Design | Uizard"
      description="Start any mobile app design project faster using Uizard's beautiful pre-made mobile app design templates. Mobile app design has never been so easy."
      url="/templates/mobile-app-templates/"
    />

    <ContentExtended narrow>
      <BreadcrumbContainer>
        <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <Breadcrumb>Mobile App Templates</Breadcrumb>
      </BreadcrumbContainer>
    </ContentExtended>

    <TemplatesSection
      title="Mobile app design templates"
      description="Start any mobile app design project faster using Uizard's beautiful, pre-made mobile app design templates. Customize your app design in just a few clicks and build out a UI prototype rapidly. 
      Whether you are designing an android app or working on something for iPhone, UI mobile app design has never been so fast and easy."
      h1={true}
      thumbnails={[
        {
          image: productivityAppImage,
          alt: "productiviy mobile app project image",
          title: "Productivity mobile app",
          link: "/templates/mobile-app-templates/productivity-mobile-app/",
        },
        {
          image: cryptoMobileAppImage,
          alt: "crypto nft mobile app project image",
          title: "Crypto NFT mobile app",
          link: "/templates/mobile-app-templates/crypto-nft-mobile-app/",
        },
        {
          image: mobileWireframeImage,
          alt: "mobile wireframe example project image",
          title: "Mobile app wireframe",
          link: "/templates/mobile-app-templates/mobile-app-wireframe/",
        },
        {
          image: audiobookImage,
          alt: "audiobook mobile app template",
          title: "Audiobook mobile app",
          link: "/templates/mobile-app-templates/audiobook-mobile-app/",
        },
        {
          image: bankingmobilewireframeImage,
          alt: "banking mobile app wireframe",
          title: "Banking mobile app wireframe",
          link: "/templates/mobile-app-templates/banking-mobile-app-wireframe/",
        },
        {
          image: billTrackerMobileAppImage,
          alt: "Bill tracker mobile app template",
          title: "Bill tracker mobile app",
          link: "/templates/mobile-app-templates/bill-tracker-mobile-app/",
        },
        {
          image: bikeImage,
          alt: "bike rental mobile app template",
          title: "Bike rental mobile app",
          link: "/templates/mobile-app-templates/bike-rental-mobile-app/",
        },
        {
          image: bookImage,
          alt: "book reading mobile app template",
          title: "Book reading mobile app",
          link: "/templates/mobile-app-templates/book-reading-mobile-app/",
        },
        {
          image: budgetImage,
          alt: "budgeting mobile app template",
          title: "Budgeting mobile app",
          link: "/templates/mobile-app-templates/budgeting-mobile-app/",
        },
        {
          image: caloriemobileImage,
          alt: "calorie counting mobile app template",
          title: "Calorie counter mobile app",
          link: "/templates/mobile-app-templates/calorie-counter-mobile-app/",
        },
        {
          image: carpoolmobileImage,
          alt: "carpool mobile app template",
          title: "Carpool mobile app",
          link: "/templates/mobile-app-templates/carpool-mobile-app/",
        },
        {
          image: carsharingImage,
          alt: "car sharing mobile app template",
          title: "Car sharing mobile app",
          link: "/templates/mobile-app-templates/car-sharing-mobile-app/",
        },
        {
          image: basketImage,
          alt: "clothing shopping app example project image",
          title: "Clothes shopping mobile app",
          link: "/templates/mobile-app-templates/sport-clothing-shopping-app/",
        },
        {
          image: cookingImage,
          alt: "cooking mobile app template",
          title: "Cooking mobile app",
          link: "/templates/mobile-app-templates/cooking-mobile-app/",
        },
        {
          image: crowdfundingmobileImage,
          alt: "crowdfunding mobile app template",
          title: "Crowdfunding mobile app",
          link: "/templates/mobile-app-templates/crowdfunding-mobile-app/",
        },
        {
          image: datingImage,
          alt: "mobile dating app template",
          title: "Dating mobile app",
          link: "/templates/mobile-app-templates/dating-mobile-app/",
        },
        {
          image: eventbookingImage,
          alt: "event booking app template",
          title: "Event booking mobile app",
          link: "/templates/mobile-app-templates/event-booking-mobile-app/",
        },
        {
          image: fitnessImage,
          alt: "mobile fitness app template",
          title: "Fitness mobile app",
          link: "/templates/mobile-app-templates/fitness-mobile-app/",
        },
        {
          image: flightImage,
          alt: "flight ticket app template",
          title: "Flight booking mobile app",
          link: "/templates/mobile-app-templates/flight-ticket-mobile-app/",
        },
        {
          image: foodblogMobileAppImage,
          alt: "Food blog mobile app template",
          title: "Blog mobile app",
          link: "/templates/mobile-app-templates/food-blog-mobile-app/",
        },
        {
          image: quickBiteMobileImage,
          alt: "food delivery mobile app project image",
          title: "Food delivery mobile app",
          link: "/templates/mobile-app-templates/mobile-food-delivery-app/",
        },
        {
          image: furniturestoreImage,
          alt: "furniture store app template",
          title: "Furniture store mobile app",
          link: "/templates/mobile-app-templates/furniture-store-mobile-app/",
        },
        {
          image: gmdeaImage,
          alt: "google material design mobile client app project image",
          title: "Google Material Design app",
          link: "/templates/mobile-app-templates/google-material-design-mobile-email-app/",
        },
        {
          image: materialYouImage,
          alt: "google material you android app project image",
          title: "Google Material You mobile app",
          link: "/templates/mobile-app-templates/google-material-you/",
        },
        {
          image: groceryImage,
          alt: "grocery delivery app template",
          title: "Grocery delivery mobile app",
          link: "/templates/mobile-app-templates/grocery-delivery-mobile-app/",
        },
        {
          image: habitImage,
          alt: "habit tracker app template",
          title: "Habit tracker mobile app",
          link: "/templates/mobile-app-templates/habit-tracker-mobile-app/",
        },
        {
          image: healthtrackerImage,
          alt: "health tracker app template",
          title: "Health tracking mobile app",
          link: "/templates/mobile-app-templates/health-tracking-mobile-app/",
        },
        {
          image: messagingAppImage,
          alt: "instant messaging app design template",
          title: "Instant messaging mobile app",
          link: "/templates/mobile-app-templates/messaging-app/",
        },
        {
          image: insuranceAppImage,
          alt: "insurance app design template",
          title: "Insurance mobile app",
          link: "/templates/mobile-app-templates/insurance-mobile-app/",
        },
        {
          image: iOSImage,
          alt: "ios 15 mobile app design template",
          title: "iOS mobile app",
          link: "/templates/mobile-app-templates/ios-mobile-app/",
        },
        {
          image: jobappImage,
          alt: "job mobile app design template",
          title: "Job search mobile app",
          link: "/templates/mobile-app-templates/job-mobile-app/",
        },
        {
          image: learningImage,
          alt: "mobile learning app design template",
          title: "Learning mobile app",
          link: "/templates/mobile-app-templates/learning-mobile-app/",
        },
        {
          image: medicalImage,
          alt: "medical mobile app design template",
          title: "Medical mobile app",
          link: "/templates/mobile-app-templates/medical-mobile-app/",
        },
        {
          image: meditationImage,
          alt: "meditation mobile app design template",
          title: "Meditation mobile app",
          link: "/templates/mobile-app-templates/meditation-mobile-app/",
        },
        {
          image: moneytransferImage,
          alt: "money transfer mobile app design template",
          title: "Money transfer mobile app",
          link: "/templates/mobile-app-templates/money-transfer-mobile-app/",
        },
        {
          image: moodboardmobileImage,
          alt: "mood board mobile app design template",
          title: "Mood board mobile app",
          link: "/templates/mobile-app-templates/mood-board-mobile-app/",
        },
        {
          image: musicMobileAppImage,
          alt: "music streaming mobile app design template",
          title: "Music streaming mobile app",
          link: "/templates/mobile-app-templates/music-streaming-app/",
        },
        {
          image: newsfeedImage,
          alt: "news feed mobile app design template",
          title: "News feed mobile app",
          link: "/templates/mobile-app-templates/news-feed-mobile-app/",
        },
        {
          image: nftmarketImage,
          alt: "nft marketplace mobile app design template",
          title: "NFT marketplace mobile app",
          link: "/templates/mobile-app-templates/nft-marketplace-mobile-app/",
        },
        {
          image: notesImage,
          alt: "notes mobile app design template",
          title: "Notes mobile app",
          link: "/templates/mobile-app-templates/notes-mobile-app/",
        },
        {
          image: photoSharingMobileAppImage,
          alt: "photo sharing social media mobile app design template",
          title: "Photo sharing mobile app",
          link: "/templates/mobile-app-templates/photo-sharing-mobile-app/",
        },
        {
          image: podcastImage,
          alt: "podcast mobile app design template",
          title: "Podcast mobile app",
          link: "/templates/mobile-app-templates/podcast-mobile-app/",
        },
        {
          image: quizmobileImage,
          alt: "quiz mobile app design template",
          title: "Quiz mobile app",
          link: "/templates/mobile-app-templates/quiz-mobile-app/",
        },
        
        {
          image: tablebookingImage,
          alt: "table booking mobile app design template",
          title: "Restaurant reservations mobile app",
          link: "/templates/mobile-app-templates/restaurant-reservations-mobile-app/",
        },
        {
          image: restaurantReviewMobileImage,
          alt: "Restaurant review mobile app design template",
          title: "Restaurant review mobile app",
          link: "/templates/mobile-app-templates/restaurant-review-mobile-app/",
        },
        {
          image: smarthomeImage,
          alt: "smart home mobile app design template",
          title: "Smart home mobile app",
          link: "/templates/mobile-app-templates/smart-home-mobile-app/",
        },
        {
          image: socialmediaImage,
          alt: "social media mobile app design template",
          title: "Social media mobile app",
          link: "/templates/mobile-app-templates/social-media-mobile-app/",
        },
        {
          image: sportAppImage,
          alt: "sport mobile app design template",
          title: "Sport mobile app",
          link: "/templates/mobile-app-templates/sports-app/",
        },
        {
          image: studyMobileAppImage,
          alt: "Study mobile app design template",
          title: "Study mobile app",
          link: "/templates/mobile-app-templates/study-mobile-app/",
        },
        {
          image: taximobileImage,
          alt: "taxi booking mobile app design template",
          title: "Taxi mobile app",
          link: "/templates/mobile-app-templates/taxi-mobile-app/",
        },
        {
          image: todolistImage,
          alt: "to do list mobile app design template",
          title: "To do list mobile app",
          link: "/templates/mobile-app-templates/to-do-list-mobile-app/",
        },
        {
          image: translationMobileAppImage,
          alt: "Translation mobile app design template",
          title: "Translation mobile app",
          link: "/templates/mobile-app-templates/translation-mobile-app/",
        },
        {
          image: transportImage,
          alt: "transport mobile app design template",
          title: "Transport mobile app",
          link: "/templates/mobile-app-templates/public-transport-mobile-app/",
        },
        {
          image: travelAppImage,
          alt: "travel mobile app design template",
          title: "Travel mobile app",
          link: "/templates/mobile-app-templates/travel-mobile-app/",
        },
        {
          image: mobileuiImage,
          alt: "universal mobile ui kit design pack",
          title: "Universal mobile UI kit",
          link: "/templates/mobile-app-templates/universal-mobile-ui-kit/",
        },
        {
          image: mobileweatherdarkImage,
          alt: "mobile weather app in dark mode",
          title: "Weather mobile app (dark)",
          link: "/templates/mobile-app-templates/weather-mobile-app-dark/",
        },
        {
          image: mobileweatherlightImage,
          alt: "mobile weather app in light mode",
          title: "Weather mobile app (light)",
          link: "/templates/mobile-app-templates/weather-mobile-app-light/",
        },
      ]}
    />

    <Content>
      <Shoutout
        headline="Design a mobile app in minutes"
        description="No tutorial needed!"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
